#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.text-shadow {
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
}

.player-container {
  min-height: 90vh;
}

.player-box {
  height: 70vh;
}

@media (max-width: 1024px) {
  .player-box {
    height: 37vh;
  }
  .player-sidebar-live {
    height: auto !important;
  }
  .player-sidebar {
    height: 70vh;
  }
}

@media (min-width: 1024px) {
  .bottom-data-wrapper {
    min-height: 42rem;
  }
}

.player-sidebar-live,
.player-sidebar {
  height: 70vh;
}

.live-chat-text {
  resize: none;
}

@media (max-width: 768px) {
  .player-player {
    top: 0;
  }
}

@media (min-width: 768px) {
  .player-player {
    bottom: 0;
  }

  .kaltura-player-container {
    border-radius: 25px;
  }
}

.glide__arrow {
  border-radius: 100% !important;
  background-color: white !important;
  color: black !important;
  height: 45px;
}

.glide__slide {
  margin-right: 15px !important;
}

.glide__arrow--left {
  left: 1% !important;
}

.glide__arrow--right {
  right: 2% !important;
}

.glide-main .glide__arrow--left {
  left: -4% !important;
}

.glide-main .glide__arrow--right {
  right: -4% !important;
}

.glide__bullets {
  bottom: 5% !important;
  left: 10% !important;
  display: none !important;
}

.tab-container {
  white-space: nowrap;
}

.play-overlay {
  left: 40%;
}

.play-overlay-main {
  left: 44%;
}

.blue-overlay {
  background-image: linear-gradient(
    0deg,
    rgba(0, 15, 30, 0) 0%,
    rgba(0, 15, 30, 0.54) 100%
  );
}

.dark-overlay::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  content: "";
  border-radius: 0.5em;
  background: linear-gradient(
    180deg,
    rgba(0, 15, 30, 0) 0,
    rgba(0, 15, 30, 0.38) 44%,
    rgba(0, 15, 30, 0.67) 100%
  );
  pointer-events: none;
}

.dark-overlay-upcoming::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(0, 15, 30, 0) 0,
    rgba(0, 15, 30, 0.38) 44%,
    rgba(0, 15, 30, 0.67) 100%
  );
  pointer-events: none;
}

.live-now-badge {
  background-color: rgba(219, 10, 66, 0.98);
}

.play-wrapper {
  position: absolute;
  -o-box-flex: 1;
  box-flex: 1;
  flex: 1 0 auto;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-end;
  max-height: calc(50% + 32px);
}

.play-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 15, 30, 0.6);
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  transition: background-color 0.1s linear;
  width: 52px;
  height: 52px;
  align-self: flex-end;
}

.play-bg:hover {
  background-color: rgba(0, 175, 255, 1);
}

.play-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmV9LmNscy0ye2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yNCAwdjI0SDBWMCIgZGF0YS1uYW1lPSJCb3VuZGluZyBhcmVhIi8+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNNy43MiAxNy40NGwxMC4zOC01YS41LjUgMCAwMDAtLjlsLTEwLjM4LTVBLjQ5LjQ5IDAgMDA3IDd2MTBhLjUuNSAwIDAwLjcyLjQ0eiIvPjwvc3ZnPg==);
  background-size: contain;
  width: 30px;
  height: 30px;
}

.react-tabs {
  width: 100%;
}

#bottom-data li.react-tabs__tab:nth-child(3),
#bottom-data li.react-tabs__tab:nth-child(4) {
  display: inline-block;
}

.react-tabs__tab-list {
  border-bottom: none !important;
  overflow: scroll;
  padding-bottom: 10px !important;
}

.react-tabs__tab-list li,
.react-tabs__tab-list li:focus {
  border-radius: 0 !important;
  border-bottom: none !important;
  border: none !important;
  color: #6b7280 !important;
  font-size: 0.875rem;
  font-weight: 500;
}

.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
  border-bottom: 2px solid #009651 !important;
  background: transparent !important;
  color: #00c064 !important;
}

@media (min-width: 1024px) {
  .lg-hidden-important {
    display: none !important;
  }

  .glide__bullets {
    display: block !important;
  }

  .upcoming #bottom-data li.react-tabs__tab:nth-child(2),
  #bottom-data li.react-tabs__tab:nth-child(3),
  #bottom-data li.react-tabs__tab:nth-child(4) {
    display: none;
  }
}

.footer-container {
  background-color: #272727;
}

.miniplayer {
  /*transform: translate3d(42px, -62px, -135px);
  transform: translate(42px, 12px);*/
}

.art-contextmenus {
  display: none !important;
}

/*addevent*/
.addeventatc-selected {
  background-color: #f9f9f9;
}
.addeventatc .addeventatc_icon {
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 1;
  left: 12px;
  top: 10px;
  background: url(https://www.addevent.com/gfx/icon-calendar-t1.svg) no-repeat;
  background-size: 18px 18px;
}
.addeventatc .start,
.addeventatc .end,
.addeventatc .timezone,
.addeventatc .title,
.addeventatc .description,
.addeventatc .location,
.addeventatc .organizer,
.addeventatc .organizer_email,
.addeventatc .facebook_event,
.addeventatc .all_day_event,
.addeventatc .date_format,
.addeventatc .alarm_reminder,
.addeventatc .recurring,
.addeventatc .attendees,
.addeventatc .calname,
.addeventatc .uid,
.addeventatc .status,
.addeventatc .method,
.addeventatc .client,
.addeventatc .transp {
  display: none !important;
}
.addeventatc br {
  display: none;
}
.addeventatc_dropdown {
  top: 0 !important;
  left: 50% !important;
  transform: translate(-50%, -100%) !important;
  width: 230px;
  position: absolute;
  padding: 6px 0px 0px 0px;
  font-family: inherit, sans-serif;
  color: #000 !important;
  font-weight: 600;
  line-height: 100%;
  background: #fff;
  font-size: 15px;
  text-decoration: none;
  text-align: left;
  margin-left: -1px;
  display: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  transform: scale(0.98, 0.98) translateY(5px);
  z-index: -1;
  transition: transform 0.15s ease;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.addeventatc_dropdown.topdown {
  transform: scale(0.98, 0.98) translateY(-5px) !important;
}
.addeventatc_dropdown span {
  display: block;
  line-height: 100%;
  background: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  color: #333;
  font-weight: 600;
  padding: 14px 10px 14px 55px;
  margin: -2px 0px;
}
.addeventatc_dropdown span:hover {
  background-color: #f4f4f4;
  color: #000;
  text-decoration: none;
  font-size: 15px;
}
.addeventatc_dropdown em {
  color: #999 !important;
  font-size: 12px !important;
  font-weight: 400;
}
.addeventatc_dropdown .frs a {
  background: #fff;
  color: #cacaca !important;
  cursor: pointer;
  font-size: 9px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 110% !important;
  padding-left: 10px;
  position: absolute;
  right: 10px;
  text-align: right;
  text-decoration: none;
  top: 5px;
  z-index: 101;
}
.addeventatc_dropdown .frs a:hover {
  color: #999 !important;
}
.addeventatc_dropdown .ateappleical {
  background: url(https://www.addevent.com/gfx/icon-apple-t5.svg) 18px 40%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ategoogle {
  background: url(https://www.addevent.com/gfx/icon-google-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateoffice365 {
  background: url(https://www.addevent.com/gfx/icon-office365-t5.svg) 19px 50%
    no-repeat;
  background-size: 18px 100%;
}
.addeventatc_dropdown .ateoutlook {
  background: url(https://www.addevent.com/gfx/icon-outlook-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateoutlookcom {
  background: url(https://www.addevent.com/gfx/icon-outlookcom-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateyahoo {
  background: url(https://www.addevent.com/gfx/icon-yahoo-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .atefacebook {
  background: url(https://www.addevent.com/gfx/icon-facebook-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .copyx {
  display: none !important;
}
.addeventatc_dropdown .brx {
  height: 1px;
  overflow: hidden;
  background: #e8e8e8;
  position: absolute;
  z-index: 100;
  left: 10px;
  right: 10px;
  top: 9px;
}
.addeventatc_dropdown.addeventatc-selected {
  transform: scale(1, 1) translateY(0px);
  z-index: 99999999999;
}
.addeventatc_dropdown.topdown.addeventatc-selected {
  transform: scale(1, 1) translateY(0px) !important;
}
.addeventatc_dropdown .drop_markup {
  background-color: #f4f4f4;
}

@media (min-width: 768px) {
  .form-edit {
    transform: translateY(-10rem);
  }
}

.lb-header {
  /*background-color: #0089d9 !important;*/
  background-color: rgba(0, 29, 61, 1) !important;
}

.lb-container {
  /*background-color: rgba( 0, 137, 217, 0.75 ) !important;*/
  background-color: rgba(0, 29, 61, 0.9) !important;
}

.dashboard-body {
  height: 42rem;
}

.sc-ezrdKe.sc-kLgntA.gdMBZx.gaMRwo,
.sc-iwyYcG.fFngYM,
.sc-cxFLnm.eHxVgR {
  display: none;
}

.sc-crrsfI.jvsKvH.rdt_TableCol_Sortable {
  font-size: 16px;
  color: rgba(107, 114, 128);
  font-weight: bold;
}

.rdt_TableCell {
  color: rgba(156, 163, 175);
  font-weight: bold;
}

.line-top::before {
  height: 0;
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  width: 50%;
  content: "";
  border-bottom: 4px solid #0089d9;
}

// .audio-progress {
// 	display: block;
// 	appearance: none;
// 	width: 100%;
// 	margin: 0;
// 	height: 30px;
// 	overflow: hidden;
//   cursor: pointer;
//   background-color: transparent;
// }

// .audio-progress:focus {
// 	outline: none;
// }

// .audio-progress::-webkit-slider-runnable-track {
// 	width: 100%;
// 	height: 30px;
// 	background: linear-gradient(to bottom, #0199ff, #0199ff) 100% 50%/100% 3px no-repeat transparent;
// }

// .audio-progress::-webkit-slider-thumb {
// 	position: relative;
// 	appearance: none;
// 	height: 13px;
// 	width: 13px;
// 	background: #0199ff;
// 	border-radius: 100%;
// 	border: 0;
// 	top: 50%;
// 	margin-top: -6.5px;

// 	transition: background-color 150ms;
// }

// .audio-progress::-moz-range-track,
// .audio-progress::-moz-range-progress {
// 	width: 100%;
// 	height: 30px;
// 	background: linear-gradient(to bottom, #cfcfcf, #cfcfcf) 100% 50%/100% 3px no-repeat transparent;
// }

// .audio-progress::-moz-range-progress {
// 	background: linear-gradient(to bottom, #0199ff, #0199ff) 100% 50%/100% 3px no-repeat transparent;
// }

// .audio-progress::-moz-range-thumb {
// 	appearance: none;
// 	margin: 0;
// 	height: 13px;
// 	width: 13px;
// 	background: #0199ff;
// 	border-radius: 100%;
// 	border: 0;
// 	transition: background-color 150ms;
// }

// .audio-progress::-ms-track {
// 	width: 100%;
// 	height: 30px;
// 	border: 0;
// 	color: transparent;
// 	background: transparent;
// }

// .audio-progress::-ms-fill-lower {
// 	background: linear-gradient(to bottom, #0199ff, #0199ff) 100% 50%/100% 3px no-repeat transparent;
// }

// .audio-progress::-ms-fill-upper {
// 	background: linear-gradient(to bottom, #cfcfcf, #cfcfcf) 100% 50%/100% 3px no-repeat transparent;
// }

// .audio-progress::-ms-thumb {
// 	appearance: none;
// 	height: 13px;
// 	width: 13px;
// 	background: #0199ff;
// 	border-radius: 100%;
// 	border: 0;
// 	transition: background-color 150ms;
// 	top: 0;
// 	margin: 0;
// 	box-shadow: none;
// }

// .audio-progress:hover::-webkit-slider-thumb,
// .audio-progress:focus::-webkit-slider-thumb {
// 	background-color: #55c2ff;
// }

// .audio-progress:hover::-moz-range-thumb,
// .audio-progress:focus::-moz-range-thumb {
// 	background-color: #55c2ff;
// }

// .audio-progress:hover::-ms-thumb,
// .audio-progress:focus::-ms-thumb {
// 	background-color: #55c2ff;
// }

// excess height to improve interactive area / accessibility
$height: 30px;
$thumb-height: 13px;
$track-height: 3px;

// colours
$upper-color: #cfcfcf;
$lower-color: #0199ff;
$thumb-color: #0199ff;
$thumb-hover-color: #55c2ff;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100%
  50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100%
  50% / 100% $track-height no-repeat transparent;

// Webkit cannot style progress so we fake it with a long shadow
// on the thumb element
@function webkit-slider-thumb-shadow($i: 1) {
  $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
  @if $i == 1 {
    @for $k from 2 through 1000 {
      $val: #{$val}, webkit-slider-thumb-shadow($k);
    }
  }
  @return $val;
}

.audio-progress {
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: $height;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

// Webkit
.audio-progress::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background;
}

.audio-progress::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  top: 50%;
  margin-top: (-$thumb-height/2);
  box-shadow: webkit-slider-thumb-shadow();
  transition: background-color 150ms;
}

// Firefox
.audio-progress::-moz-range-track,
.audio-progress::-moz-range-progress {
  width: 100%;
  height: $height;
  background: $upper-background;
}

.audio-progress::-moz-range-progress {
  background: $lower-background;
}

.audio-progress::-moz-range-thumb {
  appearance: none;
  margin: 0;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
}

// Internet Exploder
.audio-progress::-ms-track {
  width: 100%;
  height: $height;
  border: 0;
  // color needed to hide track marks
  color: transparent;
  background: transparent;
}

.audio-progress::-ms-fill-lower {
  background: $lower-background;
}

.audio-progress::-ms-fill-upper {
  background: $upper-background;
}

.audio-progress::-ms-thumb {
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
  // IE Edge thinks it can support -webkit prefixes
  top: 0;
  margin: 0;
  box-shadow: none;
}

.audio-progress:hover,
.audio-progress:focus {
  &::-webkit-slider-thumb {
    background-color: $thumb-hover-color;
  }
  &::-moz-range-thumb {
    background-color: $thumb-hover-color;
  }
  &::-ms-thumb {
    background-color: $thumb-hover-color;
  }
}

.ais-SearchBox-input {
  background-color: #f3f4f6;
  padding: 0;
  border: none;
  outline: none;
  font-size: 14px;
  width: 96%;

  &:active,
  &:focus {
    color: rgb(17, 24, 39);
  }

  &:focus {
    border: none;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  svg {
    fill: #9ca3b0;
  }
}

.results-panel {
  z-index: 76;
  margin-top: 115px;
  left: 0;
}

.ais-SearchBox-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .ais-SearchBox-input {
    width: 260px;
  }
  .results-panel {
    // width: 100vw;
    // margin-left: -90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30px);
  }
}

.ais-Hits-list {
  overflow: scroll;
  height: 90vh;
}

.art-video-player {
  z-index: 19 !important;
}

.sc-iwjezw,
.iGaJpU {
  background-image: none !important;
}

.logo-border {
  background-image: linear-gradient(
    90.69deg,
    #f2f8ff 13.42%,
    #94c6ff 42.37%,
    #0d80ff 103.09%
  );
  padding: 2px;
}

.course-slide li.glide__slide {
  height: auto;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

@font-face {
  font-family: "BoehringerForwardHead";
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardHead/BoehringerForwardHead-Bd.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardHead";
  font-style: italic;
  font-weight: 900;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardHead/BoehringerForwardHead-BdIt.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardHead";
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardHead/BoehringerForwardHead-Md.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardHead";
  font-style: italic;
  font-weight: 500;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardHead/BoehringerForwardHead-MdIt.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardHead";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardHead/BoehringerForwardHead.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardHead";
  font-style: italic;
  font-weight: 400;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardHead/BoehringerForwardHead-It.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardText";
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardText/BoehringerForwardText-Bd.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardText";
  font-style: italic;
  font-weight: 900;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardText/BoehringerForwardText-BdIt.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardText";
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardText/BoehringerForwardText-Md.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardText";
  font-style: italic;
  font-weight: 500;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardText/BoehringerForwardText-MdIt.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardText";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardText/BoehringerForwardText.woff)
    format("woff");
}

@font-face {
  font-family: "BoehringerForwardText";
  font-style: italic;
  font-weight: 400;
  src: url(/fonts/Boehringer_Forward/BoehringerForwardText/BoehringerForwardText-It.woff)
    format("woff");
}
